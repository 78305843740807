
  import { defineComponent, reactive, computed, PropType, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { helpers } from "@vuelidate/validators";

  import AppDatePicker from '@/components/stateless/AppDatePicker.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IErrorFields, IActivity, IActivityForm, TIndexedObject, ICategory } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { activityFormRules } from '@/views/admin/activities/activities-validation-rules';
  import { validatioinDatesService } from '@/services';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'ActivityForm',

    components: { AppDatePicker, AppInput, AppSelect, AppButton, AppTextarea, AppIcon },

    props: {
      data: {
        type: Object as PropType<IActivityForm>,
        required: true
      },

      categoriesList: {
        type: Array as PropType<ICategory[]>,
        default: () => []
      },

      typesList: {
        type: Array as PropType<TIndexedObject>,
        default: () => []
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['create', 'remove', 'update'],

    setup(props, { emit }) {
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        name: '',
        summary: '',
        description: '',
        startDate: '',
        endDate: '',
        link: '',
        type: '',
        category: ''
      });

      const rules = computed<any>(() => (
        {
          ...activityFormRules,
          startDate: {
            ...activityFormRules.startDate,
            validDate: helpers.withMessage(
              'Invalid Start or End date',
              () => validatioinDatesService.validateStartEndDate(data.value.startDate, data.value.endDate)
            )
          },
          endDate: {
            ...activityFormRules.endDate,
            validDate: helpers.withMessage(
              'Invalid Start or End date',
              () => validatioinDatesService.validateStartEndDate(data.value.startDate, data.value.endDate)
            )
          }
        }
      ));

      const v$ = useVuelidate(rules, data.value);

      const datePickerOptions = {
        // Disable past dates  "8.64e7" Need to subtract the number from current timestamp so that we don't disable "today" 
        disabledDate(time: any) { return time.getTime() < Date.now() - 8.64e7; }
      };

      const dataForSubmit = computed<IActivity>(() => {
        const {
          name,
          summary,
          description,
          startDate,
          endDate,
          link,
          type,
          category,
        } = data.value;

        return {
          name,
          summary,
          description,
          startDate,
          endDate,
          link,
          type: type.value,
          categoryId: category.id
        };
      });

      async function onSubmit() {

        if (await v$.value.$validate()) { 
          if (props.mode === 'create') {
            emit('create', dataForSubmit.value); 
          } else {
            emit('update');
          }
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        datePickerOptions,
        routesNames,

        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
