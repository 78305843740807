<template>
  <!-- A C T I O N  B U T T O N S -->
  <div class="mb-12 flex justify-between">
    <div
      class="flex items-center mb-12 text-grey-fp-50 cursor-pointer"
      @click="router.push({ name: routesNames.adminActivities })"
    >
      <AppIcon
        name="arrow-thin-left"
        class="mr-10"
      />
      <p class="text-md">Back to Upcoming Activities</p>
    </div>
    <div class="flex items-start justify-end">
      <AppButton
        type="primary"
        class="mr-10 px-30"
        :disabled="disabledAction"
        @click="onSubmit"
      >
        Save
      </AppButton>
    </div>
  </div>

  <!-- F O R M -->
  <div class="px-25 py-10">
    <form class="max-w-625" @submit.prevent="onSubmit">
      <div class="max-w-350">
        <AppInput
          v-model="v$.name.$model"
          label="Activity name"
          name="name"
          class="mb-20"
          maxlength="100"
          :error="errorMessages.name"
          @focus="clearErrorField('name')"
        />

        <AppSelect
          v-model="v$.category.$model"
          label="Category"
          option-key="name"
          key-value="id"
          :disabled="mode === 'edit'"
          :options="mode === 'create' ? categoriesList : [v$.category.$model]"
          :error="errorMessages.category"
          class="mb-20"
          @focus="clearErrorField('category')"
        />

        <AppSelect
          v-model="v$.type.$model"
          label="Type"
          option-key="label"
          key-value="value"
          :options="typesList"
          :error="errorMessages.type"
          class="mb-20"
          @focus="clearErrorField('type')"
        />

        <AppDatePicker
          v-model="v$.startDate.$model"
          label="Start Date"
          type="datetime"
          class="mb-20"
          format="YYYY-MM-DD hh:mm:ss A"
          :picker-options="datePickerOptions"
          :error="errorMessages.startDate"
          @focus="clearErrorField(['startDate', 'endDate'])"
        />

        <AppDatePicker
          v-model="v$.endDate.$model"
          label="End Date"
          type="datetime"
          format="YYYY-MM-DD hh:mm:ss A"
          class="mb-20"
          :picker-options="datePickerOptions"
          :error="errorMessages.endDate"
          @focus="clearErrorField(['endDate', 'startDate'])"
        />
      </div>

      <AppInput
        v-model="v$.link.$model"
        label="link"
        name="link"
        class="mb-20"
        placeholder="https://www.eventbrite.com"
        :error="errorMessages.link"
        @focus="clearErrorField('link')"
      />
      <AppTextarea
        v-model="v$.description.$model"
        label="Description"
        placeholder="Description"
        :error="errorMessages.description"
        class="w-full"
        @focus="clearErrorField('description')"
      />
    </form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, PropType, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { helpers } from "@vuelidate/validators";

  import AppDatePicker from '@/components/stateless/AppDatePicker.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IErrorFields, IActivity, IActivityForm, TIndexedObject, ICategory } from '@/types';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { activityFormRules } from '@/views/admin/activities/activities-validation-rules';
  import { validatioinDatesService } from '@/services';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'ActivityForm',

    components: { AppDatePicker, AppInput, AppSelect, AppButton, AppTextarea, AppIcon },

    props: {
      data: {
        type: Object as PropType<IActivityForm>,
        required: true
      },

      categoriesList: {
        type: Array as PropType<ICategory[]>,
        default: () => []
      },

      typesList: {
        type: Array as PropType<TIndexedObject>,
        default: () => []
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      disabledAction: {
        type: Boolean,
        default: false
      }
    },

    emits: ['create', 'remove', 'update'],

    setup(props, { emit }) {
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        name: '',
        summary: '',
        description: '',
        startDate: '',
        endDate: '',
        link: '',
        type: '',
        category: ''
      });

      const rules = computed<any>(() => (
        {
          ...activityFormRules,
          startDate: {
            ...activityFormRules.startDate,
            validDate: helpers.withMessage(
              'Invalid Start or End date',
              () => validatioinDatesService.validateStartEndDate(data.value.startDate, data.value.endDate)
            )
          },
          endDate: {
            ...activityFormRules.endDate,
            validDate: helpers.withMessage(
              'Invalid Start or End date',
              () => validatioinDatesService.validateStartEndDate(data.value.startDate, data.value.endDate)
            )
          }
        }
      ));

      const v$ = useVuelidate(rules, data.value);

      const datePickerOptions = {
        // Disable past dates  "8.64e7" Need to subtract the number from current timestamp so that we don't disable "today" 
        disabledDate(time: any) { return time.getTime() < Date.now() - 8.64e7; }
      };

      const dataForSubmit = computed<IActivity>(() => {
        const {
          name,
          summary,
          description,
          startDate,
          endDate,
          link,
          type,
          category,
        } = data.value;

        return {
          name,
          summary,
          description,
          startDate,
          endDate,
          link,
          type: type.value,
          categoryId: category.id
        };
      });

      async function onSubmit() {

        if (await v$.value.$validate()) { 
          if (props.mode === 'create') {
            emit('create', dataForSubmit.value); 
          } else {
            emit('update');
          }
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        datePickerOptions,
        routesNames,

        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>