import { required, helpers, url } from "@vuelidate/validators";


const activityFormRules = {
  name: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  summary: {},
  description: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  startDate: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  endDate: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  link: {
    required: helpers.withMessage('This field cannot be empty', required),
    url: helpers.withMessage('Invalid URL', url)
  },
  type: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  category: {
    required: helpers.withMessage('This field cannot be empty', required),
  }
};

export {
  activityFormRules
}; 